import ROUTES from "../routes/routes";

export const pages = [
  {
    name: "About",
    link: ROUTES.ABOUT,
  },
  { name: "Contact Us", link: ROUTES.CONTACTS },
  { name: "Pricing", link: ROUTES.PRICING },
  { name: "Services", link: ROUTES.SERVICES },
//  { name: "Team", link: ROUTES.TEAM },
];
