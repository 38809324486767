import { Route, Routes } from "react-router";
import ROUTES from "./routes";
import Home from "../pages/Home";
import Contacts from "../pages/Contact";
import Pricing from "../pages/Pricing";
import Services from "../pages/Services";
import Team from "../pages/Team";
import About from "../pages/About";

function AppRoutes() {
  return (
    <Routes>
      <Route path={ROUTES.HOME} element={<Home />} />
      <Route path={ROUTES.PRICING} element={<Pricing />} />
      <Route path={ROUTES.CONTACTS} element={<Contacts />} />
      <Route path={ROUTES.SERVICES} element={<Services />} />
      <Route path={ROUTES.TEAM} element={<Team />} />
      <Route path={ROUTES.ABOUT} element={<About />} />
    </Routes>
  );
}

export default AppRoutes;
