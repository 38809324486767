const HOME = "/";
const PRICING = "/pricing";
const SERVICES = "/services";
const TEAM = "/team";
const CONTACTS = "/contacts";
const ABOUT = "/about";

const ROUTES = { HOME, PRICING, SERVICES, TEAM, CONTACTS, ABOUT };

export default ROUTES;
