import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router";
import logo from "../assets/logo-icon.svg";
import { pages } from "../utils/constants";
import MobileDrawer from "./MobileDrawer";

const settings = ["Profile", "Account", "Dashboard", "Logout"];

function NavigationBar() {
  const [open, setOpen] = React.useState<boolean>(false);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "black" }} >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="a"
            onClick={() => navigate("/")}
            sx={{
              display: { xs: "none", md: "flex" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              color: "inherit",
              textDecoration: "none",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Box
              component="img"
              src={logo}
              alt="logo"
              width="40px"
              height="100%"
              padding={2}
            />
            <Typography
              variant="h6"
              noWrap
              component="a"
              onClick={() => navigate("/")}
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                fontSize: "1.4rem",
                textDecoration: 'none',
              }}
            >
              HarbourHelper
            </Typography>

          </Box>
          <Box sx={{ flexGrow: 2, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box
            component="a"
            onClick={() => navigate("/")}
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1.5,
              fontFamily: "monospace",
              fontWeight: 700,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Box
              component="img"
              src={logo}
              alt="logo"
              width="40px"
              height="100%"
              padding={2}
            />
            <Typography
              variant="h6"
              noWrap
              component="a"
              onClick={() => navigate("/")}
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                fontSize: "1.4rem",
                textDecoration: 'none',
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center"
              }}
            >
              HarbourHelper
            </Typography>
          </Box>
          <Box
            sx={{
              flexGrow: 20,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => navigate(page.link)}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  textTransform: "capitalize",
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Button
            sx={{
              display: "flex",
              bgcolor: "lightblue",
              p: 2,
              borderRadius: "12px",
              justifyContent: "center",
              alignItems: "center",
              color: "black",
              textDecoration: "none",
              textTransform: "capitalize",
              my: 2,
              "&&:hover": {
                color: "white",
                bgcolor: "#343a55",
              },
            }}
            href="/"
          >
            Check out Demo
          </Button>
        </Toolbar>
      </Container>
      <MobileDrawer open={open} setOpen={setOpen} />
    </AppBar>
  );
}
export default NavigationBar;
