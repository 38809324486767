import { Box, Grid, styled, Typography } from "@mui/material";
import Title from "./Title";
// img
import imgDetail from "../assets/image1.jpg";
import imgDetail2 from "../assets/image2.jpg";

const GetStarted = () => {
  const CustomGridItem = styled(Grid)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  });

  const CustomTypography = styled(Typography)({
    fontSize: "1.1rem",
    textAlign: "start",
    lineHeight: "1.5",
    color: "#515151",
    marginTop: "1.5rem",
  });

  return (
    <Grid
      container
      spacing={{ xs: 4, sm: 4, md: 0 }}
      sx={{
        py: 10,
        px: 2,
      }}
    >
      <CustomGridItem item xs={12} sm={8} md={6}>
        <Box component="article" sx={{ px: 4 }}>
          <Title
            text={"HarbourHelper: Revolutionizing Port Clearance"}
            textAlign={"start"}
          />
          <CustomTypography>
            Discover how HarbourHelper’s AI-driven solutions are transforming port clearance processes. Our advanced technology reduces paperwork by up to 90%, ensuring secure and efficient operations for maritime businesses.
          </CustomTypography>
        </Box>
      </CustomGridItem>

      <Grid item xs={12} sm={4} md={6}>
        <img src={imgDetail} alt="Beautifull areal view of a fleet" style={{ width: "100%" }} />
      </Grid>

      <Grid item xs={12} sm={4} md={6} sx={{ order: { xs: 4, sm: 4, md: 3 } }}>
        <img src={imgDetail2} alt="Very busy container Terminal" style={{ width: "100%" }} />
      </Grid>

      <CustomGridItem
        item
        xs={12}
        sm={8}
        md={6}
        sx={{ order: { xs: 3, sm: 3, md: 4 } }}
      >
        <Box component="article" sx={{ px: 4 }}>
          <Title
            text={"HarbourHelper Achievements"}
            textAlign={"start"}
          />
          <CustomTypography>
            Explore HarbourHelper's achievements in port clearance:
            <ul>
              <li>90% Reduction in Paperwork: HarbourHelper drastically cuts down on paperwork time, streamlining port clearance processes.</li>
              <li>1000+ Satisfied Mariners: We’ve helped over 1000 clients simplify their port clearance with our secure and seamless solutions.</li>
              <li>5000+ Processed Documents: Over 5000 documents processed, ensuring compliance and efficiency every time.</li>
              <li>50+ AI-Driven Solutions: Our team of 50+ experts is dedicated to providing advanced AI solutions for port clearance.</li>
            </ul>
          </CustomTypography>
        </Box>
      </CustomGridItem>
    </Grid>
  );
};

export default GetStarted;
