import { BrowserRouter } from "react-router-dom";
import NavigationBar from "./components/NavigationBar";
import AppRoutes from "./routes/AppRoutes";
import { Box } from "@mui/material";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <Box
      sx={{
        minHeight: "120vh",
        width: "100vw",
        marginTop: { xs: "50px", md: "60px" },
      }}
    >
      <BrowserRouter>
        <NavigationBar />
        <AppRoutes />
        <Footer />
      </BrowserRouter>
    </Box>
  );
}

export default App;
