import React from "react";
import Carousel from "react-material-ui-carousel";
import {
  Box,
  Button,
  styled,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { Link } from "react-router-dom";

import imgDetail1 from "../assets/image1.jpg";
import imgDetail2 from "../assets/image2.jpg";
import imgDetail3 from "../assets/image3.jpg";

const Header = () => {
  const CustomBox = styled(Box)(({ theme }) => ({
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  }));

  const BoxText = styled(Box)(({ theme }) => ({
    flex: "1",
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  const carouselItems = [
    {
      title: "AI-Driven Port Clearance",
      description: "Simplifying port clearance with advanced AI technology. Streamline your operations and reduce manual paperwork significantly.",
      background: imgDetail1,
    },
    {
      title: "Efficient Port Management",
      description: "HarbourHelper: Your ultimate assistant for seamless port clearance. Reduce paperwork by up to 90% with our AI-driven solutions. Our professional team is committed to making port clearance efficient and secure.",
      background: imgDetail2,
    },
    {
      title: "Innovative Port Solutions",
      description: "HarbourHelper: Smart port clearance solutions to optimize your operations. Boost efficiency, cut down on paperwork, and achieve compliance effortlessly. Join us for innovative, stress-free port management.",
      background: imgDetail3,
    },
  ];

  return (
    <CustomBox component="header">
      <Box sx={{ width: "100%", height: "80vh", overflow: "hidden" }}>
        <Carousel indicators={false}>
          {carouselItems.map((item, index) => (
            <Box
              key={index}
              sx={{
                width: "100%",
                height: "80vh",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: `url(${item.background})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                opacity: 0.7,
                color: "white",
                textAlign: "center",
              }}
            >
              <Card
                sx={{
                  position: "absolute",
                  bottom: 60,
                  left: 0,
                  height: "30%",
                  width: {md:"33.33%", xs:"50%"},
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  textAlign: "left",
                }}
              >
                <CardContent>
                  <Typography variant="h4" component="div">
                    {item.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {item.description}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Carousel>
      </Box>

      {/* Box text */}
      <BoxText component="section" sx={{ marginY: "60px" }}>
        <Typography
          variant="h2"
          component="h1"
          sx={{
            fontWeight: 700,
            color: "#fff",
          }}
        >
          Transform Your Port Clearance with Advanced AI Solutions
        </Typography>

        <Typography
          component="p"
          sx={{
            py: 3,
            lineHeight: 1.6,
            color: "#fff",
          }}
        >
          Your ultimate assistant for seamless port clearance. Reduce paperwork by up to 90% with 
          our AI-driven solutions. Our professional team is committed to making port clearance efficient 
          and secure. Ready to transform your operations?
        </Typography>

        <Box>
          <Button
            variant="contained"
            sx={{
              mr: 2,
              px: 4,
              py: 1,
              fontSize: "1rem",
              textTransform: "capitalize",
              borderRadius: 0,
              borderColor: "#14192d",
              color: "#fff",
              backgroundColor: "#14192d",
              "&&:hover": {
                backgroundColor: "#343a55",
              },
              "&&:focus": {
                backgroundColor: "#343a55",
              },
            }}
          >
            Contact Us
          </Button>
          <Button
            component={Link}
            to={"/about"}
            variant="outlined"
            sx={{
              px: 4,
              py: 1,
              fontSize: "1rem",
              textTransform: "capitalize",
              borderRadius: 0,
              color: "#fff",
              backgroundColor: "transparent",
              borderColor: "#fff",
              "&&:hover": {
                color: "#343a55",
                borderColor: "#343a55",
              },
              "&&:focus": {
                color: "#343a55",
                borderColor: "#343a55",
              },
            }}
          >
            Explore
          </Button>
        </Box>
      </BoxText>
    </CustomBox>
  );
};

export default Header;
