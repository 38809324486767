import React from "react";
import { Button, Stack } from "@mui/material";
import Title from "./Title";
import Paragraph from "./Paragraph";
import { Link } from "react-router-dom";

const GetInTouch = () => {
  return (
    <Stack
      component="section"
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        py: 10,
        mx: 6,
      }}
    >
      <Title
        text="Transform Your Port Clearance with AI Technology"
        textAlign="center"
      />
      <Paragraph
        text="Streamline your port clearance process with our advanced AI solutions. Our technology reduces paperwork by up to 90%, provides real-time updates, and ensures compliance with all regulations. Connect with us today to discover how our AI-driven platform can optimize your operations and enhance efficiency."
        maxWidth="sm"
        mx={0}
        textAlign="center"
      />
      <Button
        component={Link}
        to="/contacts"
        variant="contained"
        size="medium"
        sx={{
          fontSize: "0.9rem",
          textTransform: "capitalize",
          py: 2,
          px: 4,
          mt: 3,
          mb: 2,
          borderRadius: 0,
          backgroundColor: "#14192d",
          "&:hover": {
            backgroundColor: "#1e2a5a",
          },
        }}
      >
        Get in Touch
      </Button>
    </Stack>
  );
};

export default GetInTouch;
