import React from "react";
import { Container, Grid, Card, CardContent, Typography, Link, Box } from "@mui/material";

export default function PricingSection() {
  return (
    <section className="bg-white dark:bg-gray-900">
      <Container maxWidth="xl" sx={{ py: 8, px: 4, mx: "auto", mb: 16, textAlign: "center" }}>
        <Box component="div" className="max-w-screen-md mx-auto text-center mb-8 lg:mb-12">
          <Typography variant="h3" sx={{ mb: 4, fontSize: "4xl", fontWeight: "bold", color: "text.primary" }}>
            Designed for AI Port Clearance Teams
          </Typography>
          <Typography variant="body1" sx={{ mb: 5, fontSize: "xl", fontWeight: "light", color: "text.secondary" }}>
            Here at HarbourHelper, we leverage AI technology to streamline port clearance processes, ensuring efficiency and compliance for maritime industries.
          </Typography>
        </Box>
        <Grid container spacing={6} justifyContent="center">
          {/* Starter Plan */}
          <Grid item xs={12} md={4}>
            <Card sx={{ mx: "auto", maxWidth: "lg", textAlign: "center", bg: "white", border: "1px solid", borderColor: "gray.100", boxShadow: "0 4px 8px rgba(0,0,0,0.1)", borderRadius: "lg", color: "text.primary", dark: { bg: "gray.800", borderColor: "gray.600", color: "black" } }}>
              <CardContent>
                <Typography variant="h3" sx={{ mb: 4, fontSize: "2xl", fontWeight: "bold" }}>
                  Starter
                </Typography>
                <Typography variant="body1" sx={{ mb: 4, fontSize: "lg", fontWeight: "light", color: "text.secondary" }}>
                  Best option for personal use & for your next project.
                </Typography>
                <Box component="div" sx={{ display: "flex", justifyContent: "center", alignItems: "baseline", my: 4 }}>
                  <Typography variant="h2" sx={{ mr: 2, fontSize: "5xl", fontWeight: "extrabold" }}>
                    $29
                  </Typography>
                  <Typography variant="body1" sx={{ color: "text.secondary" }}>
                    /month
                  </Typography>
                </Box>
                <Box component="ul" sx={{ mb: 8, textAlign: "left", listStyle: "none", padding: 0 }}>
                  <Box component="li" sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                    <Typography variant="body1" sx={{ ml: 2 }}>Individual configuration</Typography>
                  </Box>
                  <Box component="li" sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                    <Typography variant="body1" sx={{ ml: 2 }}>No setup, or hidden fees</Typography>
                  </Box>
                  <Box component="li" sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                    <Typography variant="body1" sx={{ ml: 2 }}>Team size: <Typography variant="body1" sx={{ fontWeight: "bold" }}>1 developer</Typography></Typography>
                  </Box>
                  <Box component="li" sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                    <Typography variant="body1" sx={{ ml: 2 }}>Premium support: <Typography variant="body1" sx={{ fontWeight: "bold" }}>6 months</Typography></Typography>
                  </Box>
                  <Box component="li" sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body1" sx={{ ml: 2 }}>Free updates: <Typography variant="body1" sx={{ fontWeight: "bold" }}>6 months</Typography></Typography>
                  </Box>
                </Box>
                <Link href="#" variant="body1" sx={{ display: "block", width: "100%", py: 2.5, mt: "auto", color: "black", bg: "primary.600", "&:hover": { bg: "primary.700" }, borderRadius: "lg", fontSize: "sm", fontWeight: "medium", textAlign: "center", textDecoration: "none", dark: { bg: "primary.900" } }}>
                  Get started
                </Link>
              </CardContent>
            </Card>
          </Grid>

          {/* Company Plan */}
          <Grid item xs={12} md={4}>
            <Card sx={{ mx: "auto", maxWidth: "lg", textAlign: "center", bg: "white", border: "1px solid", borderColor: "gray.100", boxShadow: "0 4px 8px rgba(0,0,0,0.1)", borderRadius: "lg", color: "text.primary", dark: { bg: "gray.800", borderColor: "gray.600", color: "black" } }}>
              <CardContent>
                <Typography variant="h3" sx={{ mb: 4, fontSize: "2xl", fontWeight: "bold" }}>
                  Company
                </Typography>
                <Typography variant="body1" sx={{ mb: 4, fontSize: "lg", fontWeight: "light", color: "text.secondary" }}>
                  Relevant for multiple users, extended & premium support.
                </Typography>
                <Box component="div" sx={{ display: "flex", justifyContent: "center", alignItems: "baseline", my: 4 }}>
                  <Typography variant="h2" sx={{ mr: 2, fontSize: "5xl", fontWeight: "extrabold" }}>
                    $99
                  </Typography>
                  <Typography variant="body1" sx={{ color: "text.secondary" }}>
                    /month
                  </Typography>
                </Box>
                <Box component="ul" sx={{ mb: 8, textAlign: "left", listStyle: "none", padding: 0 }}>
                  <Box component="li" sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                    <Typography variant="body1" sx={{ ml: 2 }}>Individual configuration</Typography>
                  </Box>
                  <Box component="li" sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                    <Typography variant="body1" sx={{ ml: 2 }}>No setup, or hidden fees</Typography>
                  </Box>
                  <Box component="li" sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                    <Typography variant="body1" sx={{ ml: 2 }}>Team size: <Typography variant="body1" sx={{ fontWeight: "bold" }}>10 developers</Typography></Typography>
                  </Box>
                  <Box component="li" sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                    <Typography variant="body1" sx={{ ml: 2 }}>Premium support: <Typography variant="body1" sx={{ fontWeight: "bold" }}>24 months</Typography></Typography>
                  </Box>
                  <Box component="li" sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body1" sx={{ ml: 2 }}>Free updates: <Typography variant="body1" sx={{ fontWeight: "bold" }}>24 months</Typography></Typography>
                  </Box>
                </Box>
                <Link href="#" variant="body1" sx={{ display: "block", width: "100%", py: 2.5, mt: "auto", color: "black", bg: "primary.600", "&:hover": { bg: "primary.700" }, borderRadius: "lg", fontSize: "sm", fontWeight: "medium", textAlign: "center", textDecoration: "none", dark: { bg: "primary.900" } }}>
                  Get started
                </Link>
              </CardContent>
            </Card>
          </Grid>

          {/* Enterprise Plan */}
          <Grid item xs={12} md={4}>
            <Card sx={{ mx: "auto", maxWidth: "lg", textAlign: "center", bg: "white", border: "1px solid", borderColor: "gray.100", boxShadow: "0 4px 8px rgba(0,0,0,0.1)", borderRadius: "lg", color: "text.primary", dark: { bg: "gray.800", borderColor: "gray.600", color: "black" } }}>
              <CardContent>
                <Typography variant="h3" sx={{ mb: 4, fontSize: "2xl", fontWeight: "bold" }}>
                  Enterprise
                </Typography>
                <Typography variant="body1" sx={{ mb: 4, fontSize: "lg", fontWeight: "light", color: "text.secondary" }}>
                  Best for large scale uses and extended redistribution rights.
                </Typography>
                <Box component="div" sx={{ display: "flex", justifyContent: "center", alignItems: "baseline", my: 4 }}>
                  <Typography variant="h2" sx={{ mr: 2, fontSize: "5xl", fontWeight: "extrabold" }}>
                    $499
                  </Typography>
                  <Typography                   variant="body1" sx={{ color: "text.secondary" }}>
                    /month
                  </Typography>
                </Box>
                <Box component="ul" sx={{ mb: 8, textAlign: "left", listStyle: "none", padding: 0 }}>
                  <Box component="li" sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                    <Typography variant="body1" sx={{ ml: 2 }}>Individual configuration</Typography>
                  </Box>
                  <Box component="li" sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                    <Typography variant="body1" sx={{ ml: 2 }}>No setup, or hidden fees</Typography>
                  </Box>
                  <Box component="li" sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                    <Typography variant="body1" sx={{ ml: 2 }}>Team size: <Typography variant="body1" sx={{ fontWeight: "bold" }}>100+ developers</Typography></Typography>
                  </Box>
                  <Box component="li" sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body1" sx={{ ml: 2 }}>Premium support: <Typography variant="body1" sx={{ fontWeight: "bold" }}>36 months</Typography></Typography>
                  </Box>
                  <Box component="li" sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body1" sx={{ ml: 2 }}>Free updates: <Typography variant="body1" sx={{ fontWeight: "bold" }}>36 months</Typography></Typography>
                  </Box>
                </Box>
                <Link href="#" variant="body1" sx={{ display: "block", width: "100%", py: 2.5, mt: "auto", color: "black", bg: "primary.600", "&:hover": { bg: "primary.700" }, borderRadius: "lg", fontSize: "sm", fontWeight: "medium", textAlign: "center", textDecoration: "none", dark: { bg: "primary.900" } }}>
                  Get started
                </Link>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

